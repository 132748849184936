<template>
  <div>
    <div class="grid grid-cols-2 sm:grid-cols-5 mb-8 w-full">
        <div class="col-span-1">
          <button
            type="button"
            class="btn btn-sm btn-red align-middle"
            @click.prevent="openActivatePndModal"
            v-if="canDo('credpal:can_activate_pnd_on_user')"
          >
            Activate PND
          </button>
        </div>
        <div class="col-span-1">
          <button
            type="button"
            class="btn btn-sm btn-green align-middle"
            @click.prevent="openDeactivatePndModal"
            v-if="canDo('credpal:can_deactivate_pnd_on_cash_wallet')"
          >
            Deactivate PND
          </button>
        </div>
        <div v-if="wallet.limit" class="col-span-1">
          <button
            type="button"
            class="btn btn-sm btn-blue align-middle"
            @click.prevent="openRemoveWalletLimitModal"
          >
            Remove Limit
          </button>
        </div>
    </div>
    <div v-if="cashWalletId">
      <div
        class="gap-8 grid grid-cols-1 mb-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3"
        v-if="walletDetails.loading"
      >
        <skeleton-loader
          v-for="index in 3"
          :key="index"
          class="opacity-10 rounded"
          height="100px"
          width="100%"
        />
      </div>

      <wallet-card v-else-if="wallet" :staff="staff" :wallet="wallet" />

      <div v-if="walletTransactionResource.show">
        <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
          <div class="col-span-1 flex items-center flex-wrap">
            <h2 class="text-lg font-bold mr-3">Transactions</h2>
          </div>
          <div class="col-span-1">
            <div class="relative">
              <search-form
                v-model="walletTransactionResource.query"
                placeholder="Search for reference, status, etc."
                @submit="(event) => $refs.table.loadAjaxData()"
              />
            </div>
          </div>
        </div>
        <datatable
          ref="table"
          :url="walletTransactionResource.url"
          :ajax="true"
          :ajax-pagination="true"
          :columns="walletTransactionResource.columns"
          :fillable="false"
          :query="walletTransactionResource.query"
          :index="true"
          :reverse-index="true"
        />
      </div>
    </div>
    <template v-else>
      <h3 class="text-center text-lg mt-5">No Wallet Found</h3>
    </template>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="activatePndModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm PND Activation</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Select reason for PND</div>

      <div class="flex flex-row items-center mb-6">
        <custom-select
          :options="pnds"
          v-model="pnd"
          class="custom-select border border-blue-500 rounded-sm pl-3 pr-6 py-2 text-xs text-blue-500"
        />
      </div>

       <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-md btn-red-soft mr-2"
          @click.prevent="closeActivatePndModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="ActivatePnd"
        >
          <span v-if="loading">Activating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="deactivatePndModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm PND Deactivation</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeDeactivatePndModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="DeactivatePnd"
        >
          <span v-if="loading">Deactivating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="removeWalletLimitModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm Limit Removal</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeRemoveWalletLimitModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="RemoveLimit"
        >
          <span v-if="loading">Removing Limit</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">Success!</div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.successModal.close()"
      >
        Okay
      </button>
    </modal>
  </div>
</template>
<script>
export default {
  components: {
    WalletCard: () => import('./WalletCard'),
  },
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      requestError: '',
      pnd: 'Fraud/Suspicious activities',
      pnds : [
        {
          title: 'Fraud/Suspicious activities',
          value: 'Fraud/Suspicious activities'
        },
        {
          title: 'Incomplete/expired Documents',
          value: 'Incomplete/expired Documents'
        },
        {
          title: 'Default on Credit card Repayment',
          value: 'Default on Credit card Repayment'
        }, 
        {
          title: 'Unbalanced statement',
          value: 'Unbalanced statement',
        },        
      ],
      walletDetails: this.$options.resource({}),
      walletTransactionResource: this.$options.resource([], {
        query: '',
        url: `${this.$baseurl}/wallet/cash/transactions/${this.staff?.cash_wallet?.wallet_id}`,
        show: false,
        columns: [
          {
            name: 'type',
            th: 'Type',
            render: ({ type }) => this.$options.filters.fromSlug(type),
          },
          {
            name: 'description',
            th: 'Description',
          },
          {
            name: 'category',
            th: 'Category',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: ({ amount }) => this.$options.filters.formatAmount(amount),
          },
          {
            name: 'status',
            th: 'Payment Status',
            render: (transaction, status) => this.transactionStatusBadge(status),
          },
          {
            name: 'reference',
            th: 'Reference',
          },
          {
            name: 'created_at',
            th: 'Date',
            render: (transaction, created_at) =>
              this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia'),
          },
        ],
      }),
    };
  },
  computed: {
    cashWalletId() {
      return this.staff?.cash_wallet?.wallet_id;
    },
    wallet() {
      return this.walletDetails.data;
    },
  },
  methods: {
    removeError() {
      this.requestError = '';
    },
    openActivatePndModal() {
      this.$refs.activatePndModal.open();
    },
    closeActivatePndModal() {
      this.$refs.activatePndModal.close();
    },
    openDeactivatePndModal() {
      this.$refs.deactivatePndModal.open();
    },
    closeDeactivatePndModal() {
      this.$refs.deactivatePndModal.close();
    },
    openRemoveWalletLimitModal() {
      this.$refs.removeWalletLimitModal.open();
    },
    closeRemoveWalletLimitModal() {
      this.$refs.removeWalletLimitModal.close();
    },
    async ActivatePnd() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/admin/personal/cash/activate-post-no-debit/${this.staff.id}`,
        data: {
          description: this.pnd,
          type: 'locked'
        },
        headers: this.headers,
        success: () => {
          this.closeActivatePndModal();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.requestError = error.response?.data?.message;
        },
      });
      this.loading = false;
    },
    async DeactivatePnd() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/admin/personal/cash/deactivate-post-no-debit/${this.staff.id}`,
        headers: this.headers,
        success: () => {
          this.closeDeactivatePndModal();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.requestError = error.response?.data?.message;
        },
      });
      this.loading = false;
    },
    async getWallet() {
      this.walletDetails.setLoading(true);
      await this.$get({
        url: `${this.$baseurl}/wallet/cash/${this.cashWalletId}`,
        headers: this.headers,
        success: (response) => (this.walletDetails.data = response.data?.data),
        error: (error) =>
          this.$error({
            title: error.response?.data?.message,
          }),
      }).finally(() => this.walletDetails.setLoading(false));
    },
    showTransactions() {
      this.walletTransactionResource.show = true;
    },
    async RemoveLimit() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/admin/personal/cash/remove-limit/${this.staff.id}`,
        headers: this.headers,
        success: () => {
          this.closeRemoveWalletLimitModal();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.requestError = error.response?.data?.message;
        },
      });
      this.loading = false;
    },
  },
  mounted() {
    if (this.cashWalletId) {
      Promise.all([this.getWallet()]).catch(console.log);
      this.$nextTick(() => this.showTransactions());
    }
  },
};
</script>
